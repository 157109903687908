$(document).ready(function() {

  /* Busca
  ------------------------------------------------------------------*/

  $('#header-menu i, #header-busca i').click(function() {
    $('#header-menu i').toggleClass('active');
    $('#header-busca').toggleClass('show');
    if ( $('#header-busca').hasClass('show') ) {
      $('#header-busca .search-field').focus();
    }
  });

  /* Mostrar Filtros das Receitas
  ------------------------------------------------------------------*/

  $('.post-type-archive-receitas #receitas #filtros .dropdown').click(function() {
    $(this).toggleClass('active');
    $('#receitas nav').toggleClass('show');
  });

  /* Slider Produtos
  ------------------------------------------------------------------*/

  var sliderProdutos = $('#slider-produtos').lightSlider({
    item: 5,
    loop: true,
    slideMove: 1,
    slideMargin: 40,
    cssEasing: 'cubic-bezier(0.25, 0, 0.25, 1)',
    speed: 400,
    pager: false,
    controls: false,
    centerSlide: true,
    onSliderLoad: function() {
      $('#slider-produtos').removeClass('cS-hidden');
    },
    responsive: [
      {
        breakpoint: 1020,
        settings: {
          item: 3
        }
      },
      {
        breakpoint: 500,
        settings: {
          item: 1
        }
      }
    ]
  });
  $('#produtos-destaque .lSPrev').click(function(){
    sliderProdutos.goToPrevSlide();
  });
  $('#produtos-destaque .lSNext').click(function(){
    sliderProdutos.goToNextSlide();
  });

  /* Slider Anos
  ------------------------------------------------------------------*/

  var sliderAnos = $('#slider-anos').lightSlider({
    gallery: true,
    item: 1,
    loop: false,
    thumbItem: 9,
    thumbMargin: 0,
    thumbText: true,
    slideMargin: 10,
    galleryMargin: 0,
    enableDrag: true,
    centerSlide: false,
    currentPagerPosition: 'left',
    prevHtml: '<',
    nextHtml: '>'
  });
  $('.lSAction.mod .lSPrev').click(function(){
    sliderAnos.goToPrevSlide();
  });
  $('.lSAction.mod .lSNext').click(function(){
    sliderAnos.goToNextSlide();
  });

  /* Slider Revistas
  ------------------------------------------------------------------*/

  var sliderRevistas = $('#slider-revistas').lightSlider({
    item: 5,
    loop: false,
    slideMove: 1,
    slideMargin: 0,
    cssEasing: 'cubic-bezier(0.25, 0, 0.25, 1)',
    speed: 400,
    pager: false,
    controls: false,
    centerSlide: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          item: 3,
          loop: false
        }
      },
      {
        breakpoint: 360,
        settings: {
          item: 1,
          loop: false
        }
      }
    ],
    onSliderLoad: function() {
      $('#slider-revistas').removeClass('cS-hidden');
    }
  });
  $('#revistas .lSPrev').click(function(){
    sliderRevistas.goToPrevSlide();
  });
  $('#revistas .lSNext').click(function(){
    sliderRevistas.goToNextSlide();
  });

  /* Slider Trabalhe Conosco
  ------------------------------------------------------------------*/

  $('.photo-slider').lightSlider({
    item: 1,
    loop: true,
    auto: true,
    pause: 3000,
    pager: false,
    slideMargin: 0,
    slideEndAnimation: false,
    prevHtml: '<',
    nextHtml: '>'
  });

  $('.depoimentos-slider').lightSlider({
    item: 3,
    loop: false,
    auto: false,
    pager: false,
    slideMargin: 40,
    slideEndAnimation: false,
    adaptiveHeight: true,
    prevHtml: '<',
    nextHtml: '>',
    responsive: [
      {
        breakpoint: 600,
        settings: {
          item: 1
        }
      }
    ],
  });

  /* Galeria Produtos
  ------------------------------------------------------------------*/

  $('.single-produtos #galeria').lightSlider({
    gallery: true,
    item: 1,
    loop: true,
    thumbItem: 3,
    slideMargin: 10,
    galleryMargin: 40,
    enableDrag: false,
    centerSlide: false,
    currentPagerPosition: 'left',
    prevHtml: '<',
    nextHtml: '>'
  });

  /* Galeria Pages
  ------------------------------------------------------------------*/

  $('.page.hentry #galeria').lightSlider({
    gallery: true,
    item: 1,
    loop: true,
    thumbItem: 3,
    thumbMargin: 20,
    slideMargin: 30,
    galleryMargin: 20,
    enableDrag: true,
    centerSlide: false,
    currentPagerPosition: 'left',
    prevHtml: '<',
    nextHtml: '>'
  });

  /* Dropdown Categoria de Produtos
  ------------------------------------------------------------------*/

  var width, bottom;
  var body = document.querySelector('body');

  var dropdown = $('.dropdown > li');
  
  dropdown.hover(function() {
    
    width = body.getBoundingClientRect().width;
    bottom = (width <= 870) ? 84 : 42;
    
    var submenu = $(this).children('ul'),
        submenuHeight = submenu.height();
    submenu.css({'bottom': - submenuHeight});

  }, function() {
    
    width = body.getBoundingClientRect().width;
    bottom = (width <= 870) ? 84 : 42;

    var submenu = $(this).children('ul'),
        submenuHeight = submenu.height();
    submenu.css({'bottom': bottom});
  });

  /* Pattern atrás dos produtos
  ------------------------------------------------------------------*/

  var produtoWidth = $('#lista-produtos figure').width(),
      recuo = -($('#lista-produtos figure').width()/2);
  $('#lista-produtos figure:before').css({
    'width': produtoWidth,
    'left': '50%',
    'margin-left': recuo
  });

  /* Isotope
  ------------------------------------------------------------------*/

  $('#lista-produtos-nothumb .grid').isotope({
    itemSelector: 'li',
    percentPosition: true
  });

  /* Adaptive Bacnkgrounds
  ------------------------------------------------------------------*/

  $.adaptiveBackground.run();

  /* SVG to PNG
  ------------------------------------------------------------------*/

  svgeezy.init(false, 'png');

  /* Trocar img svg para svg inline
  ------------------------------------------------------------------*/

  $.replaceToInlineSVG.run();

  /* Funcionamento do menu de navegação do header para telas menores
  ------------------------------------------------------------------*/

  $('.nav-header-icon').click(function(){
    $('.menu-wrapper').css('right', '30px');

    if($('#header-menu i').hasClass('active')) {
      $('#header-menu i').toggleClass('active');
      $('#header-busca').toggleClass('show');
    }
  });

  $('#close-nav').click(function(){
    $('.menu-wrapper').css('right', '-280px');
  });

  $('.menu-wrapper i').click(function(){
    $('#close-nav').click();
  });

});
